function removeHash (to) {
  if (to.hash) return { path: to.path, query: to.query, hash: '' }
}
const routes = [
  {
    path: '/:path*',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'revision', component: () => import('pages/ConfigurationPage.vue') }
    ],
    beforeEnter: [removeHash]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
